import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const videos = document.querySelectorAll(
    '[data-js-module="textarea-richtext"]'
  );
  if (videos) {
    const { default: TextareaRichtext } = await import(
      /* webpackChunkName: "textarea-richtext" */ './textarea-richtext'
    );

    forEach(videos, (index, element) => {
      new TextareaRichtext(element);
    });
  }
}
