import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="search"]');

  if (elements) {
    const { default: Search } = await import(
      /* webpackChunkName: "search" */ './search.jsx'
    );

    forEach(elements, (index, element) => new Search(element));
  }
}
