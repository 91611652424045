import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="search-results"]'
  );

  if (elements) {
    const { default: SearchResults } = await import(
      /* webpackChunkName: "search-results" */ './search-results.jsx'
    );

    forEach(elements, (index, element) => new SearchResults(element));
  }
}
