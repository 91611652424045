import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="survey"]');

  if (elements) {
    const { default: Survey } = await import(
      /* webpackChunkName: "survey" */ './survey.jsx'
    );

    forEach(elements, (index, element) => new Survey(element));
  }
}
