import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="third-party-content"]'
  );

  if (elements.length) {
    const { default: ThirdPartyContent } = await import(
      /* webpackChunkName: "third-party-content" */ './third-party-content'
    );

    forEach(elements, (index, element) => new ThirdPartyContent(element));
  }
}
