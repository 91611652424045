export default async function init() {
  const element = document.querySelector('[data-js-module="sticky-cta"]');
  if (element) {
    const { default: StickyCta } = await import(
      /* webpackChunkName: "sticky-cta" */ './sticky-cta'
    );

    new StickyCta(element);
  }
}
