import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const tabs = document.querySelectorAll('[data-js-module="tabs"]');
  if (tabs) {
    const { default: Tabs } = await import(
      /* webpackChunkName: "tabs" */ './tabs'
    );

    forEach(tabs, (index, element) => {
      return new Tabs(element);
    });
  }
}
