import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const videos = document.querySelectorAll('[data-js-module="video"]');
  if (videos) {
    const { default: Video } = await import(
      /* webpackChunkName: "video" */ './video'
    );

    forEach(videos, (index, element) => {
      if (element.dataset.jsThirdPartyContent) return;
      new Video(element);
    });
  }
}
