export default async function init() {
  const mainStage = document.querySelector('[data-js-module="main-stage"]');
  if (mainStage) {
    const { default: MainStage } = await import(
      /* webpackChunkName: "main-stage" */ './main-stage'
    );

    return new MainStage(mainStage);
  }
}
