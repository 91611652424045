export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="image-upload"]'
  );

  const initImageUpload = async () => {
    const { default: ImageUpload } = await import(
      /* webpackChunkName: "image-upload" */ './image-upload'
    );
    elements.forEach((element) => new ImageUpload(element));
  };

  if (elements.length) initImageUpload();
}
