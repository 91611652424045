import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="teaser-regions"]'
  );

  if (elements) {
    const { default: TeaserRegions } = await import(
      /* webpackChunkName: "teaser-regions" */ './teaser-regions.jsx'
    );

    forEach(elements, (index, element) => new TeaserRegions(element));
  }
}
