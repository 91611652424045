import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="watchlist-button"]'
  );

  if (elements) {
    const { default: WatchlistButton } = await import(
      /* webpackChunkName: "watchlist-button" */ './watchlist-button.jsx'
    );

    forEach(elements, (index, element) => new WatchlistButton(element));
  }
}
