/**
 * forEach Method for NodeLists
 *
 * @param {Array} array Array to walk
 * @param {Object} callback Callback Method for each entry
 * @param {Object} scope If there has to be a scope change
 * @returns {void}
 */
export default (array, callback, scope) => {
  for (let i = 0; i < array.length; i += 1) {
    callback.call(scope, i, array[i]);
  }
};
