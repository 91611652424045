import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="accordion"]');
  if (elements) {
    const { default: Accordion } = await import(
      /* webpackChunkName: "accordion" */ './accordion'
    );

    forEach(elements, (index, element) => new Accordion(element));
  }
}
