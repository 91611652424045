import forEach from '../../../javascripts/dom/forEach';

export default () => {
  const elements = document.querySelectorAll('[data-js-module="select"]');

  const init = async () => {
    const { default: Select } = await import(
      /* webpackChunkName: "select" */ './select'
    );

    forEach(elements, (index, select) => new Select(select));
  };

  if (elements.length) init();
};
