import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const forms = document.querySelectorAll('[data-js-module="gallery"]');

  const { default: Gallery } = await import(
    /* webpackChunkName: "gallery" */ './gallery'
  );

  forEach(forms, (index, element) => new Gallery(element));
}
