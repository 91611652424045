import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const forms = document.querySelectorAll('[data-js-module="form"]');

  const { default: Form } = await import(
    /* webpackChunkName: "form" */ './form'
  );

  forEach(forms, (index, element) => new Form(element));
}
