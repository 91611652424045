import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const navigation = document.querySelectorAll('[data-js-module="navigation"]');

  const { default: Navigation } = await import(
    /* webpackChunkName: "navigation" */ './navigation'
  );

  forEach(navigation, (index, element) => new Navigation(element));
}
