import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const floatingLayer = document.querySelectorAll(
    '[data-js-module="floating-layer"]'
  );

  const { default: FloatingLayer } = await import(
    /* webpackChunkName: "floating-layer" */ './floating-layer'
  );

  forEach(floatingLayer, (index, element) => new FloatingLayer(element));
}
