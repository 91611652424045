import '../stylesheets/main.scss';

// Use what-input to track input method
import 'what-input';

import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';

import domready from 'domready';
import dayjs from 'dayjs';

import localeData from 'dayjs/plugin/localeData';

// Shims
import forEach from './dom/forEach';

import initImage from '../components/image/javascripts';
import '../components/icon/javascripts';

import initSelect from '../components/select/javascripts';
import initStickyCta from '../components/sticky-cta/javascripts';
import initAccordion from '../components/accordion/javascripts';
import initInput from '../components/input/javascripts';
import initFileUpload from '../components/file-upload/javascripts';
import initImageUpload from '../components/image-upload/javascripts';
import initDialog from '../components/dialog/javascripts';
import initPaywall from '../components/paywall/javascripts';
import initForm from '../components/form/javascripts';
import initTabs from '../components/tabs/javascripts';
import initVideo from '../components/video/javascripts';
import initMainStage from '../components/main-stage/javascripts';
import initFloatingLayer from '../components/floating-layer/javascripts';
import initNavigation from '../components/navigation/javascripts';
import initTeaserRegions from '../components/teaser-regions/javascripts';
import initSearch from '../components/search/javascripts';
import initSearchResults from '../components/search-results/javascripts';
import initWatchlistButton from '../components/watchlist-button/javascripts';
import initWatchlist from '../components/watchlist/javascripts';
import initTeaserFocusFilter from '../components/teaser-focus-filter/javascripts';
import initTeaserContainer from '../components/teaser-container/javascripts';
import initThirdPartyContent from '../components/third-party-content/javascripts';
import initSurvey from '../components/survey/javascripts';
import initPartnerLogos from '../components/partner-logos/javascripts';
import initTextareaRichtext from '../components/textarea-richtext/javascripts';
import initGallery from '../components/gallery/javascripts';

dayjs.extend(localeData);
dayjs.locale(document.documentElement.lang);

function enhancedDomReady(init) {
  domready(init);
  window.addEventListener('reload', init);
}

enhancedDomReady(() => {
  initImage();
  initSelect();
  initInput();
  initFileUpload();
  initImageUpload();
  initStickyCta();
  initAccordion();
  initDialog();
  initPaywall();
  initForm();
  initTabs();
  initVideo();
  initMainStage();
  initFloatingLayer();
  initNavigation();
  initTeaserRegions();
  initSearch();
  initSearchResults();
  initWatchlist();
  initWatchlistButton();
  initTeaserFocusFilter();
  initTeaserContainer();
  initThirdPartyContent();
  initSurvey();
  initPartnerLogos();
  initTextareaRichtext();
  initGallery();
});

// All links with target="_blank" attribute should have an attribute rel="noopener". This has performance and security reasons https://developers.google.com/web/tools/lighthouse/audits/noopener
forEach(document.querySelectorAll("[target='_blank']"), (index, link) => {
  link.setAttribute('rel', 'noopener');
});

/**
 * Update Algolia Insights according to CookieFirst consent
 *
 * @see https://www.algolia.com/doc/guides/building-search-ui/events/react/#persist-an-anonymous-usertoken-across-sessions
 * @see https://www.algolia.com/doc/api-reference/api-methods/init/?client=javascript#method-param-userhasoptedout
 * @see https://support.cookiefirst.com/hc/de/articles/360011568738-Javascript-API
 * @returns {void}
 */
const onConsentUpdate = () => {
  if (!window.aa) {
    return;
  }

  const consent = window.CookieFirst?.consent;
  window.aa('init', {
    useCookie: consent?.performance,
    userHasOptedOut: !consent?.performance,
    partial: true
  });
};

window.addEventListener('cf_init', () => onConsentUpdate());
window.addEventListener('cf_consent', () => onConsentUpdate());
onConsentUpdate();
