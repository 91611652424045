import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const dialog = document.querySelectorAll('[data-js-module="dialog"]');

  const { default: Dialog } = await import(
    /* webpackChunkName: "dialog" */ './dialog'
  );

  forEach(dialog, (index, element) => new Dialog(element));
}
