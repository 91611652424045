import api from "!../../.yarn/__virtual__/style-loader-virtual-f9f16405f6/0/cache/style-loader-npm-2.0.0-b9a5c4a2aa-21425246a5.zip/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../.yarn/__virtual__/css-loader-virtual-d30052927e/0/cache/css-loader-npm-5.2.7-e1e8b8d16f-fb0742b30a.zip/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../.yarn/__virtual__/postcss-loader-virtual-153c499f26/0/cache/postcss-loader-npm-6.2.1-45828eb0de-e40ae79c3e.zip/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../.yarn/__virtual__/resolve-url-loader-virtual-51a613e702/0/cache/resolve-url-loader-npm-4.0.0-2a9c18d86b-8e5bcf9786.zip/node_modules/resolve-url-loader/index.js!../../.yarn/__virtual__/sass-loader-virtual-bd043c55e2/0/cache/sass-loader-npm-12.3.0-2fec8d367f-b755e6d5ce.zip/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].use[4]!../../.yarn/__virtual__/mini-css-extract-plugin-virtual-67046e4afa/0/cache/mini-css-extract-plugin-npm-1.6.2-f19d00566e-c2c1f3d7e5.zip/node_modules/mini-css-extract-plugin/dist/loader.js??ruleSet[1].rules[3].use[0]!../../.yarn/__virtual__/css-loader-virtual-d30052927e/0/cache/css-loader-npm-5.2.7-e1e8b8d16f-fb0742b30a.zip/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../.yarn/__virtual__/postcss-loader-virtual-153c499f26/0/cache/postcss-loader-npm-6.2.1-45828eb0de-e40ae79c3e.zip/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[3].use[2]!../../.yarn/__virtual__/resolve-url-loader-virtual-51a613e702/0/cache/resolve-url-loader-npm-4.0.0-2a9c18d86b-8e5bcf9786.zip/node_modules/resolve-url-loader/index.js!../../.yarn/__virtual__/sass-loader-virtual-bd043c55e2/0/cache/sass-loader-npm-12.3.0-2fec8d367f-b755e6d5ce.zip/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[3].use[4]!./main.scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};