import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const partnerLogos = document.querySelectorAll(
    '[data-js-module="partner-logos"]'
  );

  const { default: PartnerLogos } = await import(
    /* webpackChunkName: "partner-logos" */ './partner-logos'
  );

  forEach(partnerLogos, (index, element) => new PartnerLogos(element));
}
